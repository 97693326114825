@import "./variables.scss";

body {
    margin: 0;
    padding: 0;
    // font-family: "Roboto", sans-serif;
    font-family: "Source Sans Pro", sans-serif;
}

.lazy-suspense-loader {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: $background-color;

    h2 {
        font-size: 1.3rem;
        font-weight: 400;
        color: $text-color;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

label {
    color: #b7b7b7;
}

a {
    color: rgb(50, 88, 240);

    &:hover {
        text-decoration: underline;
        color: rgb(82, 116, 253);
    }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $background-color inset !important;
    -webkit-box-shadow: 0 0 0 30px $background-color inset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.color-white {
    color: #ffffff
}

.main-circular-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000ad;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    overflow: hidden;
}

.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 100vh;
    width: 100%;
    background-color: $background-color;
    color: $text-color;
}

.main-table-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ba;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.universal-table {
    position: relative;
    max-height: 550px !important;
    overflow: auto;

    table {
        th {
            position: relative;
            font-weight: 600;
            color: #a19a9a;

            &:last-child {
                &:after {
                    content: none;
                }
            }

            &:after {
                content: "";
                background: rgb(82, 82, 82);
                position: absolute;
                top: 26%;
                // bottom: 0;
                right: 0;
                height: 50%;
                width: 1px;
            }
        }

        tr {
            td:last-child {
                padding: 0px;
            }

            &:last-child {
                td {
                    border: none;
                }
            }
        }
    }
}

.custom-textarea-input {
    // outline: none;
    min-width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-radius: 12px;
    color: $text-color;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    padding: 8px;
    border-color: #595959;
}

.universal-view-table {
    border-collapse: collapse;
    width: 100%;
    word-break: break-word;

    // tbody {
    tr {
        border-bottom: 1px solid #585757;
        border-width: auto;

        // width: fit-content;
        th {
            align-items: start;
            // text-align: start;
            margin-right: 5px;
            width: 170px;
            color: rgb(164, 164, 164);
            border: 1px solid #585757;

            span {
                display: block;
                text-align: start;
            }

            // justify-content: end;
        }

        td {
            border: 1px solid #585757;
            padding-top: 5px;
            padding-left: 5px;
            padding-bottom: 5px;
            // text-align: center;
        }
    }

    // }
}

.add-mentor-score-btn {
    background: #ff0071;
    padding: 0px 5px;
    border-radius: 3px;
    font-size: 17px;
    cursor: pointer;
}