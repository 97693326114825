.add-new-container {
  padding: 6%;
  background-color: $background-color;
  // padding-right: min(20px, 6%);
  // padding-top: min(40px, 6%);
  // padding-bottom: min(40px, 6%);
  // padding-left: min(20px, 6%);
  border-radius: 16px;
  margin: auto;
}

.tab-view-container {
  .items {
    background-color: rgb(60, 60, 60);
    // margin: 6px;
    padding: 12px 6px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      cursor: pointer;
      text-align: center;

    }

    &.selected {
      background-color: #FF0071;
    }

    &.not-selected {
      &:hover {
        background-color: grey;
      }
    }
  }
}

.image-upload-container {
  .file-upload-mask {
    display: flex;
    border-style: dotted;
    cursor: pointer;
    height: 100%;
    min-height: 100px;
    min-width: 100px;
    align-items: center;
    justify-content: center;
  }

  .image-preview {
    height: 100%;
    min-height: 100px;
    min-width: 100px;
    width: 100px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.pagination-container {
  border-radius: 25.93px;
  margin: 16px 0px;
  padding: 5px;
  width: fit-content;
  float: right;

  .MuiPaginationItem-root {
    color: white
  }

  .MuiPaginationItem-root.Mui-selected {
    background: #132944;

    &:hover {
      opacity: 0.8;
      background: #132944;
    }
  }
}